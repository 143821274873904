<template>
  <div>
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar size="40px">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
          <span class="text--primary font-weight-semibold mb-n1">
            {{name}}
          </span>
            <small class="text--disabled text-capitalize">{{position}}</small>
          </div>
        </div>

        <v-divider></v-divider>
        <v-divider></v-divider>
        <v-list-item link v-if=" position==='ADMIN'"
                     @click="load_transmital_or_dialog = true ">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiContentSaveMove }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Load Transmital Or</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link
                     v-if="position==='BRANCH MANAGER'  && date_is_can_new_month"
                     @click="start_new_month_transaction">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiReload }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Start New Month Transaction</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>
        <v-list-item link @click="is_change_password = true">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link @click="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--    dialog here-->
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <restore-db-file-dialog
      v-on:dialog_value="on_restore_db_file_close"
      :dialog_show="restore_db_file_dialog"
    />
    <load-transmital-or
      v-on:dialog_value="on_restore_db_file_close"
      :dialog_show="load_transmital_or_dialog"
    />
  </div>
</template>

<script>
  import {
    mdiContentSave,
    mdiContentSaveMove,
    mdiChatOutline,
    mdiCogOutline,
    mdiHelpCircleOutline,
    mdiLogoutVariant,
    mdiReload,
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import ChangePassword from '@/views/dashboard/ChangePassword'
  import restoreDbFileDialog from '../../components/dialogs/RestoreDbFile'
  import loadTransmitalOr from '../../components/dialogs/LoadTransmitalOr'

  export default {
    components: {
      restoreDbFileDialog,
      loadTransmitalOr,
      ChangePassword,
    },
    data() {
      return {
        is_change_password: false,
        show: false,
        txt: '',
        date_is_can_new_month: false,
        load_transmital_or_dialog: false,
        restore_db_file_dialog: false,

        icons: {
          mdiContentSave,
          mdiContentSaveMove,
          mdiReload,
          mdiChatOutline,
          mdiCogOutline,
          mdiHelpCircleOutline,
          mdiLogoutVariant,
        },
      }
    },
    mounted() {
      this.conditions_for_new_month()
    },
    watch: {
      month_end: function () {
        this.conditions_for_new_month()
      },
    },
    computed: {
      ...mapGetters('authentication', ['name', 'position', 'id_no', 'branch_id', 'branch', 'can_new_month', 'month_end', 'month_of']),
    },
    methods: {
      ...mapActions('authentication', ['logout_credentials']),
      ...mapActions('monthly_collection_reports', ['monthly_collection_report']),
      ...mapActions('monthly_quota_reports', ['monthly_quota_report']),
      ...mapActions('transaction_months', ['start_new_transaction_month']),
      ...mapActions('loans_payment', ['start_new_month_loan_payments']),
      ...mapActions('start_new_month', ['is_can_new_month', 'sendText']),
      ...mapActions('db_file', ['create_db_file']),
      on_restore_db_file_close(value) {
        this.restore_db_file_dialog = value
      },
      conditions_for_new_month() {
        var end_date = moment(this.month_end, 'MMMM DD, YYYY');
        if (moment(end_date).isBefore(moment())) {
          this.date_is_can_new_month = true
        }
      },
      tool_generate_db() {
        this.show = true
        this.txt = 'Generating...Please Wait...'
        this.create_db_file({
          file_name: 'goodlife-coop-' + moment().format('MM-DD-YYYY-hh-mm-ss') + '.pptx',
        })
          .then(() => {
            this.txt = ''
            this.show = false
          })
          .catch(error => {
            console.log(error.response.data.message)
          })
      },
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no);
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      start_new_month_transaction() {
        var start = moment().clone().startOf('month').format('MMMM DD, YYYY')
        var end = moment().clone().endOf('month').format('MMMM DD, YYYY')


        const data3 = new FormData()
        data3.append('branch_id', this.branch_id);
        this.is_can_new_month(data3)
          .then(resolve => {
            if (resolve.data[0].proceed) {
              this.show = true
              this.txt = 'COLLECTING DATA & SAVING DATA ON MONTHLY COLLECTION REPORTS'

              const data = new FormData()
              data.append('branch_id', this.branch_id);
              data.append('month_of', this.month_of);
              data.append('month_end', this.month_end);
              data.append('is_new_month', 1);
              data.append('type_of_loan', '');
              data.append('collector_id', 0);
              this.monthly_collection_report(data)
                .then(() => {
                  this.show = true
                  this.txt = 'COLLECTING DATA & SAVING DATA ON MONTHLY QUOTA REPORTS'
                  data.append('is_new_month', 2);
                  this.monthly_quota_report(data)
                    .then(() => {
                      this.monthly_collection_report(data)
                        .then(() => {
                          this.show = true
                          this.txt = 'GENERATE PENALTIES OF UNPAID LOANS'
                          this.start_new_month_loan_payments(data)
                            .then(() => {
                              const data2 = new FormData()
                              data2.append('month_of', moment().format('MMMM YYYY'));
                              data2.append('year', moment().year());
                              data2.append('month_start', start);
                              data2.append('month_end', end);
                              data2.append('branch_id', this.branch_id);
                              this.txt = 'STARTING NEW MONTH TRANSACTIONS...'
                              this.start_new_transaction_month(data2)
                                .then(response => {
                                  this.sendText(data)
                                  this.txt = response.data + ' AND AUTOMATICALLY LOGOUT!'
                                  this.logout()
                                })
                                .catch(error => {
                                  console.log(error)
                                })
                            })
                            .catch(error => {
                              console.log(error)
                            })
                        })
                        .catch(error => {
                          console.log(error)
                        })
                    })
                    .catch(error => {
                      console.log(error)
                    })
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.show = true
              this.txt = resolve.data[0].message

              if (this.timeout) clearTimeout(this.timeout)
              this.timeout = setTimeout(() => {
                this.txt = ''
                this.show = false
              }, 2000)

            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
  }
</script>

<style lang="scss">
  .user-profile-menu-content {
    .v-list-item {
      min-height: 2.5rem !important;
    }
  }
</style>
