<template>
  <v-layout column wrap>
    <v-snackbar
      v-model="dialog_show"
      :timeout="5000"
      :color="color"
      top="top"
      right="right"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    data() {
      return {
        dialog_show: false
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar'])
    },
    watch: {
      snackbar_flag: function () {
        this.dialog_show = this.snackbar_flag
      },
      dialog_show: function () {
        if (!this.dialog_show) {
          this.change_snackbar({
            show: false,
            color: 'warning',
            text: ''
          })
        }
      }
    },
    props: {
      snackbar_flag: Boolean,
      color: String,
      snackbar_text: String,
    },
  }
</script>
