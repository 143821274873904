<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="450"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/main-logo.png')"
          max-height="40px"
          max-width="40px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h6 class="text--primary">
            GOODLIFE SAVINGS & CREDIT COOPERATIVE
          </h6>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link :icon="icons.mdiAccountGroupOutline" title="ORGANIZATIONAL CHART"
                     :to="{ name: 'organizational-chart'}"
      ></nav-menu-link>
      <nav-menu-group
        title="CALCULATOR"
        :icon="icons.mdiCalculator"
      >
        <nav-menu-link class="ml-4"
                       title="LOAN"
                       :to="{ name: 'loan-calculator'}"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="TIME DEPOSIT"
                       :to="{ name: 'time-deposit-calculator'}"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER' || position==='ACCOUNT'"
        title="Deposit Slip"
        :to="{ name: 'new-deposit-slip' }"
        :icon="icons.mdiBankTransfer"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER'"
        title="Deposit Share Capital"
        :to="{ name: 'share-capital-for-approval' }"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER'"
        title="Withdrawal Slip"
        :to="{ name: 'withdrawals-selection' }"
        :icon="icons.mdiCashMinus"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER' || position==='ACCOUNT'"
        title="Payments"
        :to="{ name: 'payments' }"
        :icon="icons.mdiCashMultiple"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER'||position==='ADMIN'"
        title="Deposit ShareCapital Not Yet Approved"
        :to="{ name: 'approval-share-capital-deposit'}"
        :icon="icons.mdiAccountOff"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='ACCOUNT'"
        title="Withdrew ShareCapital Not Yet Approved"
        :to="{ name: 'approval-share-capital-withdrew'}"
        :icon="icons.mdiAccountOff"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='ACCOUNT'"
        title="Withdrew TimeDep Not Yet Approved"
        :to="{ name: 'approval-time-dep-withdrew'}"
        :icon="icons.mdiAccountOff"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='ACCOUNT'"
        title="Withdrew Loan Savings Not Yet Approved"
        :to="{ name: 'approval-loan-savings-withdrew'}"
        :icon="icons.mdiAccountOff"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'||position==='BRANCH MANAGER'"
        title="Bank Tally"
        :to="{ name: 'bank-tally'}"
        :icon="icons.mdiBankCheck"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Banks Statement Tally"
        :to="{ name: 'bank-statement-tally'}"
        :icon="icons.mdiBankCheck"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Load Inventories"
        :to="{ name: 'load-inventories' }"
        :icon="icons.mdiCubeScan"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Inventory Report"
        :to="{ name: 'inventory-report' }"
        :icon="icons.mdiClipboardArrowDownOutline"
      ></nav-menu-link>
      <nav-menu-section-title v-if="position==='ADMIN'"
                              title="LOAD INTERFACE"></nav-menu-section-title>


      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Transmital Passbooks"
        :to="{ name: 'transmital-passbooks' }"
        :icon="icons.mdiBookArrowRightOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Passbooks"
        :to="{ name: 'load-passbooks' }"
        :icon="icons.mdiBookOpenVariant"
      ></nav-menu-link>
      <nav-menu-section-title v-if="position==='TELLER'"
                              title="TELLER INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        v-if="position==='TELLER' && today_time>7 && today_time<17"
        title="Printing Passbook"
        :to="{ name: 'regular-savings-passbook-printing' }"
        :icon="icons.mdiPrinter"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='TELLER'||position==='BRANCH MANAGER'||position==='ADMIN'"
        title="Passbooks Monitoring"
        :to="{ name: 'passbooks-monitoring' }"
        :icon="icons.mdiBookOpenVariant"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='TELLER'||position==='BRANCH MANAGER'||position==='ADMIN'"
        title="UnDeposited Passbooks Monitoring"
        :to="{ name: 'undeposited-passbooks-monitoring' }"
        :icon="icons.mdiBookOpenVariant"
      ></nav-menu-link>

      <nav-menu-section-title v-if="position==='ADMIN'||position==='GENERAL MANAGER'"
                              title="ADMIN INTERFACE"></nav-menu-section-title>
      <!--        CAIS REPORTS-->
      <nav-menu-group
        title="CAIS REPORTS"
        :icon="icons.mdiChartBarStacked"
        v-if="position==='ADMIN'||position==='GENERAL MANAGER'"
      >
        <nav-menu-link class="ml-4"
                       title="Membership Count"
                       :to="{ name: 'cais-membership-count'}"
                       :icon="icons.mdiAccountGroupOutline"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Age Group of Members"
                       :to="{ name: 'cais-age-group-members'}"
                       :icon="icons.mdiAccountGroupOutline"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Gross Sales/Receipt"
                       :to="{ name: 'sales-on-receipt'}"
                       :icon="icons.mdiCash100"
        ></nav-menu-link>
      </nav-menu-group>
      <!--        CAIS REPORTS END-->
      <!--        Income Statement-->
      <nav-menu-group
        v-if="position==='ADMIN'"
        title="Income Statement"
        :icon="icons.mdiCurrencyPhp"
      >
        <nav-menu-link class="ml-4"

                       title="Income Summary"
                       :to="{ name: 'income-summary' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="Loan Interest Income BreakDown"
                       :to="{ name: 'loan-interest-income-summary' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Income Statement End-->
      <!--        Expenses Statement-->
      <nav-menu-group
        v-if="position==='ADMIN'"
        title="Expenses Statement"
        :icon="icons.mdiCurrencyUsdOff"
      >
        <nav-menu-link class="ml-4"
                       title="Register Expenses"
                       :to="{ name: 'register-expenses' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Expenses Summary"
                       :to="{ name: 'expenses-summary' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Expenses Statement End-->
      <!--      Payables Statement-->
      <nav-menu-group
        v-if="position==='ADMIN'"
        title="Payables Statement"
        :icon="icons.mdiCashRefund"
      >
        <nav-menu-link class="ml-4"
                       title="Payables Summary"
                       :to="{ name: 'payables-summary' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Regular Savings Payables BreakDown"
                       :to="{ name: 'regular-savings-payables-summary' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Loan Savings Payables BreakDown"
                       :to="{ name: 'loan-savings-payables-summary' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Time Deposit Payables BreakDown"
                       :to="{ name: 'time-deposit-payables-summary' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Payables Statement End-->
      <!--        LIST OF MEMBER-->
      <nav-menu-link
        v-if="position==='ADMIN'||position==='GENERAL MANAGER'"
        title="List of Members"
        :to="{ name: 'list-of-members' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <!--        LIST OF MEMBER END-->
      <!--        YEARLY DIVIDEND-->
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Yearly Dividend"
        :to="{ name: 'yearly-dividend' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="For Released Yearly Dividend"
        :to="{ name: 'yearly-dividend-for-released' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <!--        YEARLY DIVIDEND-->
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Financial Statement"
        :to="{ name: 'financial-statement' }"
        :icon="icons.mdiCurrencyPhp"
      ></nav-menu-link>
      <!--        TELLER FUND-->
      <nav-menu-link
        v-if="position==='ADMIN'"
        title="Teller Fund"
        :to="{ name: 'teller-fund' }"
        :icon="icons.mdiCashMultiple"
      ></nav-menu-link>

      <nav-menu-section-title v-if="position==='BRANCH MANAGER'||position==='ADMIN'"
                              title="BRANCH INTERFACE"></nav-menu-section-title>
      <!--        TELLER FUND END-->
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT' && position!='GENERAL MANAGER'"
        title="Transfer Loans to Collector"
        :to="{ name: 'transfer-accounts-to-collectors' }"
        :icon="icons.mdiTransitTransfer"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of DelinquentAccount"
        :to="{ name: 'list-of-delinquent-account' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Loan Not Yet Approved"
        :to="{ name: 'list-of-loan-not-yet-approved' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Loans Transmittal"
        :to="{ name: 'pending-loans-transmital' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-section-title v-if="position!='TELLER'"
                              title="USER INTERFACE"></nav-menu-section-title>
      <!--        Register-->
      <nav-menu-group
        title="Register"
        :icon="icons.mdiPencilOutline"
        v-if="position!='TELLER'&& position!='GENERAL MANAGER'"
      >
        <nav-menu-link class="ml-4"
                       v-if="position==='ACCOUNT'"
                       title="New Membership"
                       :to="{ name: 'new-membership' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ACCOUNT'"
                       title="New Corporate Account"
                       :to="{ name: 'new-corporate-account' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ACCOUNT'"
                       title="New Coop Protek Membership"
                       :to="{ name: 'open-coop-protek-savings' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='BRANCH MANAGER'"
                       title="New Company Staff"
                       :to="{ name: 'new-staff-info' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='BRANCH MANAGER'"
                       title="New Organizational Group"
                       :to="{ name: 'new-organization-group' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ADMIN'"
                       title="New Bank Depositories"
                       :to="{ name: 'new-bank-depositories' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ADMIN'"
                       title="New Branch Details"
                       :to="{ name: 'new-branch-details' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ADMIN'"
                       title="New User"
                       :to="{ name: 'new-user' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='ADMIN'"
                       title="New Committee/Officer"
                       :to="{ name: 'new-officer-or-committee' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Register End-->
      <!--        Create-->
      <nav-menu-group
        v-if="position==='BRANCH MANAGER' || position==='ACCOUNT'"
        title="Create"
        :icon="icons.mdiFolderPlusOutline"
      >
        <nav-menu-link class="ml-4"
                       v-if="position==='BRANCH MANAGER'"
                       title="New Loan Approved"
                       :to="{ name: 'loan-application' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='BRANCH MANAGER'"
                       title="New Loan Transmital"
                       :to="{ name: 'new-loan-transmital' }"
        ></nav-menu-link>
        <!--        <nav-menu-link class="ml-4"-->
        <!--                       v-if="position==='BRANCH MANAGER'"-->
        <!--                       title="New Appliance/Gadget Loan"-->
        <!--                       :to="{ name: 'gadget-loan-application' }"-->
        <!--        ></nav-menu-link>-->
        <nav-menu-link class="ml-4"
                       v-if="position==='ACCOUNT'"
                       title="New Open Account Savings"
                       :to="{ name: 'open-account-savings' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position==='BRANCH MANAGER'"
                       title="New Raw Lots Rent"
                       :to="{ name: 'pages-register_interface' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--        Create End-->
      <!--      Search-->
      <nav-menu-group
        v-if="position!='TELLER'"
        title="Search"
        :icon="icons.mdiAccountSearchOutline"
      >
        <nav-menu-link class="ml-4"
                       title="Members"
                       :to="{ name: 'search-members'}"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Coop Protek Members"
                       :to="{ name: 'search-coop-protek-members'}"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position!='ACCOUNT'"
                       title="Regular Savings Members"
                       :to="{ name: 'search-regular-savings-member'}"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       v-if="position!='ACCOUNT' && position!='TELLER'"
                       title="OR # in Remittances"
                       :to="{ name: 'search-or-no-remittances'}"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Search End-->
      <nav-menu-section-title
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="VIEW INTERFACE"></nav-menu-section-title>
      <!--      Bank Depository-->
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Bank Depository"
        :to="{ name: 'list-of-bank-depositories'}"
        :icon="icons.mdiBankOutline"
      ></nav-menu-link>
      <!--      Bank Depository End-->
      <!--        Monitoring-->
      <nav-menu-group
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Monitoring"
        :icon="icons.mdiMonitor"
      >
        <nav-menu-link class="ml-4"
                       title="Official Receipt"
                       :to="{ name: 'monitoring-official-receipt' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"
                       title="Official Receipt Data"
                       :to="{ name: 'pages-register_interface' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--      Monitoring End-->
      <!--      Remittances-->
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Remittances"
        :to="{ name: 'remittances'}"
        :icon="icons.mdiCash100"
      ></nav-menu-link>
      <!--      Remittances End-->

      <!--      Raw Lots Data-->
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Raw Lots Data"
        :to="{ name: 'raw-lots-data'}"
        :icon="icons.mdiScatterPlotOutline"
      ></nav-menu-link>
      <!--      Raw Lots Data End-->
      <nav-menu-section-title v-if="position!='TELLER'"
                              title="REPORTS INTERFACE"></nav-menu-section-title>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Yearly BM Incentives"
        :to="{ name: 'yearly-branch-manager-incentives' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="BM Incentives"
        :to="{ name: 'branch-manager-incentives' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Loan"
        :to="{ name: 'list-of-loans' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Loan Savings"
        :to="{ name: 'list-of-loans-savings' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Loan Savings Withdrew"
        :to="{ name: 'list-of-loans-savings-withdrew' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Gadget Loan"
        :to="{ name: 'list-of-gadget-loans' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Loan Insurance"
        :to="{ name: 'list-of-loan-insurance' }"
        :icon="icons.mdiAccountCashOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Mortuary Insurance"
        :to="{ name: 'list-of-have-mortuary' }"
        :icon="icons.mdiShieldAccount"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER'"
        title="List of New Member"
        :to="{ name: 'list-of-new-members' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER'"
        title="List of New Corporate Members"
        :to="{ name: 'list-of-new-corporate-members' }"
        :icon="icons.mdiAccountGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Terminated Members"
        :to="{ name: 'terminated-members' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Time Deposits"
        :to="{ name: 'list-of-time-deposits' }"
        :icon="icons.mdiClipboardClockOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Time Deposits Not Yet Released"
        :to="{ name: 'time-deposit-not-yet-released' }"
        :icon="icons.mdiClipboardClockOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER'"
        title="List of Open Account"
        :to="{ name: 'list-of-open-accounts' }"
        :icon="icons.mdiCashPlus"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER'"
        title="List of Coop Protek Account"
        :to="{ name: 'list-of-coop-protek-accounts' }"
        :icon="icons.mdiCashPlus"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Processing Fee"
        :to="{ name: 'list-of-processing-fee' }"
        :icon="icons.mdiReload"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of NMAPI"
        :to="{ name: 'list-of-nmapi-fee' }"
        :icon="icons.mdiReload"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Share Capital"
        :to="{ name: 'list-of-share-capital' }"
        :icon="icons.mdiShareVariantOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of REGSave Deducted From Loan"
        :to="{ name: 'list-of-reg-save-from-loan' }"
        :icon="icons.mdiShareVariantOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Foundation"
        :to="{ name: 'list-of-foundation' }"
        :icon="icons.mdiLightbulbGroupOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="List of Raw Lots Payment"
        :to="{ name: 'list-of-raw-lots-payment' }"
        :icon="icons.mdiCreditCardCheckOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Unpaid Loans"
        :to="{ name: 'unpaid-loans' }"
        :icon="icons.mdiCashRemove"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Paid Loans"
        :to="{ name: 'paid-loans' }"
        :icon="icons.mdiCashCheck"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Monthly Collection Report"
        :to="{ name: 'monthly-collection-reports' }"
        :icon="icons.mdiBookmarkMultipleOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Monthly Interest Collection Report"
        :to="{ name: 'monthly-interest-collection-reports' }"
        :icon="icons.mdiBookmarkMultipleOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Monthly Principal Collection Report"
        :to="{ name: 'monthly-principal-collection-reports' }"
        :icon="icons.mdiBookmarkMultipleOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Monthly Quota Report"
        :to="{ name: 'monthly-quota-reports' }"
        :icon="icons.mdiBookmarkMultipleOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Summary Collection Report"
        :to="{ name: 'summary-collection-report' }"
        :icon="icons.mdiChartBarStacked"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='TELLER' && position!='ACCOUNT'"
        title="Summary"
        :to="{ name: 'summary-report' }"
        :icon="icons.mdiChartMultiline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position!='ACCOUNT'"
        title="Teller Transactions"
        :to="{ name: 'teller-transaction' }"
        :icon="icons.mdiFinance"
      ></nav-menu-link>
      <nav-menu-section-title v-if="position!='ADMIN' && position!='ACCOUNT'"
                              title="LOAD INTERFACE"></nav-menu-section-title>
      <!--        Deposit Slip Teller-->
      <nav-menu-group
        v-if="position=='TELLER' && today_time>7 && today_time<17"
        title="Deposit Slip"
        :icon="icons.mdiBankTransfer"
      >
        <nav-menu-link class="ml-4"

                       title="Regular Savings"
                       :to="{ name: 'new-deposit-slip-regular-savings' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="Share Capital"
                       :to="{ name: 'share-capital-application' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="From Approved Share Capital"
                       :to="{ name: 'share-capital-from-approved' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="From Loan Share Capital"
                       :to="{ name: 'share-capital-from-loan' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="From Loan Regular Savings"
                       :to="{ name: 'regular-savings-from-loan' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="Time Deposit"
                       :to="{ name: 'time-deposit-application' }"
        ></nav-menu-link>
        <nav-menu-link class="ml-4"

                       title="Time Deposit Less Than 100k"
                       :to="{ name: 'time-deposit-application-less' }"
        ></nav-menu-link>
      </nav-menu-group>
      <!--        Deposit Slip Teller End-->

      <nav-menu-link
        v-if="position=='TELLER' && today_time>7 && today_time<17"
        title="Withdrawal Slip"
        :to="{ name: 'new-withdrawal-slip-regular-savings' }"
        :icon="icons.mdiCashMinus"
      ></nav-menu-link>

      <nav-menu-link
        v-if="position==='BRANCH MANAGER'"
        title="OR Series"
        :to="{ name: 'load-or-series' }"
        :icon="icons.mdiReceipt"
      ></nav-menu-link>
      <nav-menu-link
        v-if="position==='BRANCH MANAGER'"
        title="OR Re-Assign"
        :to="{ name: 'or-reassigned' }"
        :icon="icons.mdiPaperRollOutline"
      ></nav-menu-link>
    </v-list>
    <!--    <a-->
    <!--      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"-->
    <!--      target="_blank"-->
    <!--      rel="nofollow"-->
    <!--    >-->
    <!--      <v-img-->
    <!--        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"-->
    <!--        alt="upgrade-banner"-->
    <!--        transition="scale-transition"-->
    <!--        class="upgrade-banner mx-auto"-->
    <!--        max-width="230"-->
    <!--      ></v-img>-->
    <!--    </a>-->
  </v-navigation-drawer>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiBankCheck,
    mdiCubeScan,
    mdiAccountOff,
    mdiHomeOutline,
    mdiCalculator,
    mdiAlphaTBoxOutline,
    mdiEyeOutline,
    mdiCreditCardOutline,
    mdiTable,
    mdiFileOutline,
    mdiFormSelect,
    mdiAccountCogOutline,
    mdiTransitTransfer,
    mdiFolderPlusOutline,
    mdiPencilOutline,
    mdiAccountSearchOutline,
    mdiCashMultiple,
    mdiBankOutline,
    mdiCurrencyPhp,
    mdiCurrencyUsdOff,
    mdiCashRefund,
    mdiMonitor,
    mdiCash100,
    mdiScatterPlotOutline,
    mdiAccountCashOutline,
    mdiShieldAccount,
    mdiAccountGroupOutline,
    mdiClipboardClockOutline,
    mdiCashPlus,
    mdiReload,
    mdiShareVariantOutline,
    mdiLightbulbGroupOutline,
    mdiCreditCardCheckOutline,
    mdiCashRemove,
    mdiCashCheck,
    mdiBookmarkMultipleOutline,
    mdiChartBarStacked,
    mdiChartMultiline,
    mdiFinance,
    mdiBankTransfer,
    mdiCashMinus,
    mdiReceipt,
    mdiPaperRollOutline,
    mdiPrinter,
    mdiBookOpenVariant,
    mdiBookArrowRightOutline,
    mdiClipboardArrowDownOutline,
  } from '@mdi/js'
  import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
  import NavMenuGroup from './components/NavMenuGroup.vue'
  import NavMenuLink from './components/NavMenuLink.vue'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";

  export default {
    components: {
      NavMenuSectionTitle,
      NavMenuGroup,
      NavMenuLink,
    },
    computed: {
      ...mapGetters('authentication', ['position']),
    },
    props: {
      isDrawerOpen: {
        type: Boolean,
        default: null,
      },
    },
    setup() {
      return {
        interval: {},
        today_time: 0,
        icons: {
          mdiHomeOutline,
          mdiBankCheck,
          mdiClipboardArrowDownOutline,
          mdiCubeScan,
          mdiAccountOff,
          mdiCalculator,
          mdiAlphaTBoxOutline,
          mdiEyeOutline,
          mdiCreditCardOutline,
          mdiTable,
          mdiFileOutline,
          mdiFormSelect,
          mdiTransitTransfer,
          mdiAccountCogOutline,
          mdiFolderPlusOutline,
          mdiPencilOutline,
          mdiAccountSearchOutline,
          mdiCashMultiple,
          mdiBankOutline,
          mdiCurrencyPhp,
          mdiCurrencyUsdOff,
          mdiCashRefund,
          mdiMonitor,
          mdiCash100,
          mdiScatterPlotOutline,
          mdiAccountCashOutline,
          mdiShieldAccount,
          mdiAccountGroupOutline,
          mdiClipboardClockOutline,
          mdiCashPlus,
          mdiReload,
          mdiShareVariantOutline,
          mdiLightbulbGroupOutline,
          mdiCreditCardCheckOutline,
          mdiCashRemove,
          mdiCashCheck,
          mdiBookmarkMultipleOutline,
          mdiChartBarStacked,
          mdiChartMultiline,
          mdiFinance,
          mdiBankTransfer,
          mdiCashMinus,
          mdiReceipt,
          mdiPaperRollOutline,
          mdiPrinter,
          mdiBookOpenVariant,
          mdiBookArrowRightOutline,
        },
      }
    },
    mounted() {
      this.interval = setInterval(this.todays_time, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    methods: {
      ...mapActions('authentication', ['change_teller_already_cut_off']),
      todays_time() {
        this.today_time = parseInt(moment().format('k'))
        if (this.today_time >= 17) {
          this.change_teller_already_cut_off(true)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .app-title {
    font-size: 1.25rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  // ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
  .app-logo {
    transition: all 0.18s ease-in-out;

    .v-navigation-drawer--mini-variant & {
      transform: translateX(-4px);
    }
  }

  @include theme(app-navigation-menu) using($material) {
    background-color: map-deep-get($material, 'background');
  }

  .app-navigation-menu {
    .v-list-item {
      &.vertical-nav-menu-link {
        ::v-deep .v-list-item__icon {
          .v-icon {
            transition: none !important;
          }
        }
      }
    }
  }

  // You can remove below style
  // Upgrade Banner
  .app-navigation-menu {
    .upgrade-banner {
      position: absolute;
      bottom: 13px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
</style>
