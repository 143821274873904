<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">CHANGE PASSWORD</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mb-2"
              v-model="old_password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Old Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="rules.non_empty_field_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mb-2"
              v-model="new_password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="New Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="rules.non_empty_field_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mb-2"
              v-model="confirm_password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Confirm Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="rules.non_empty_field_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import {mdiAlertOutline, mdiEyeOffOutline, mdiEyeOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      show: false,
      txt: '',

      saving_request: false,
      alert: false,
      alert_message: '',

      old_password: '',
      new_password: '',
      confirm_password: '',
      isPasswordVisible: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiEyeOutline,
          mdiEyeOffOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['user_id', 'id_no']),
    },
    methods: {
      ...mapActions('authentication', ['logout_credentials']),
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('users', ['update_password']),
      logout() {
        this.show = true
        this.txt = 'LOGGING OUT.....'

        const data = new FormData()
        data.append('id_no', this.id_no)
        this.logout_credentials(data)
          .then(() => {
            this.$router.push({path: '/'})
            this.show = false
            this.txt = ''
          })
          .catch(error => {
            console.log(error)
            this.show = false
            this.txt = ''
          })
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.user_id);
          data.append('old_password', this.old_password);
          data.append('new_password', this.new_password);
          data.append('confirm_password', this.confirm_password);
          this.update_password(data)
            .then(response => {
              if (response.status === 200) {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.show = true
                this.txt = response.data
                if (this.timeout) clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                  this.txt = ''
                  this.show = false
                  this.logout()
                }, 3000)
              } else {
                this.alert = true
                this.alert_message = response.data
              }
              this.saving_request = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving_request = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
