import axios from 'axios'

axios.defaults.withCredentials = true

const state = {
  authenticated: false,

  user_id: 0,
  id_no: 0,
  position: 'NA',
  name: 'NA',

  teller_already_cut_off: false,
  is_generating_savings_interest: 0,

  branch_id: 0,
  branch: 'NA',
  branch_address: 'NA',
  branch_contact_no: 'NA',

  month_of: 'NA',
  can_new_month: 0,
  year: 'NA',
  month_start: 0,
  month_end: 'NA',
}

const mutations = {
  set_authenticated(state, authenticated) {
    state.authenticated = authenticated
  },

  set_name(state, name) {
    state.name = name
  },
  set_position(state, position) {
    state.position = position
  },
  set_user_id(state, user_id) {
    state.user_id = user_id
  },
  set_id_no(state, id_no) {
    state.id_no = id_no
  },


  set_month_of(state, month_of) {
    state.month_of = month_of
  },
  can_new_month(state, can_new_month) {
    state.can_new_month = can_new_month
  },


  set_teller_already_cut_off(state, teller_already_cut_off) {
    state.teller_already_cut_off = teller_already_cut_off
  },
  is_generating_savings_interest(state, is_generating_savings_interest) {
    state.is_generating_savings_interest = is_generating_savings_interest
  },


  set_branch_id(state, branch_id) {
    state.branch_id = branch_id
  },
  set_branch(state, branch) {
    state.branch = branch
  },
  set_branch_address(state, branch_address) {
    state.branch_address = branch_address
  },
  set_branch_contact_no(state, branch_contact_no) {
    state.branch_contact_no = branch_contact_no
  },

  set_year(state, year) {
    state.year = year
  },
  set_month_start(state, month_start) {
    state.month_start = month_start
  },
  set_month_end(state, month_end) {
    state.month_end = month_end
  },
}

const getters = {
  authenticated: state => state.authenticated,

  user_id: state => state.user_id,
  id_no: state => state.id_no,
  name: state => state.name,
  position: state => state.position,

  is_generating_savings_interest: state => state.is_generating_savings_interest,
  teller_already_cut_off: state => state.teller_already_cut_off,

  branch_id: state => state.branch_id,
  branch: state => state.branch,
  branch_address: state => state.branch_address,
  branch_contact_no: state => state.branch_contact_no,

  month_of: state => state.month_of,
  can_new_month: state => state.can_new_month,
  year: state => state.year,
  month_start: state => state.month_start,
  month_end: state => state.month_end,
}

const actions = {
  change_month: ({commit}, month_of) => {
    commit('set_month_of', month_of)
  },
  change_branch_id: ({commit}, data) => {
    commit('set_branch_id', data.id)
    commit('set_branch', data.branch_code)
    commit('set_branch_address', data.address)
    commit('set_branch_contact_no', data.contact_no)

    commit('can_new_month', data.can_new_month)
    commit('set_month_of', data.transaction_month.month_of)
    commit('set_year', data.transaction_month.year)
    commit('set_month_start', data.transaction_month.month_start)
    commit('set_month_end', data.transaction_month.month_end)
  },
  change_is_generating_savings_interest: ({commit}, is_generating_savings_interest) => {
    commit('is_generating_savings_interest', is_generating_savings_interest)
  },
  change_teller_already_cut_off: ({commit}, teller_already_cut_off) => {
    commit('set_teller_already_cut_off', teller_already_cut_off)
  },
  login_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.get('/sanctum/csrf-cookie').then(() => {
          axios.post('api/login-user-profile', data)
            .then(response => {
              if (response.data == 'The provided USER INFO are incorrect.'
                || response.data == 'The USER is already Login') {
                commit('set_authenticated', false)
              } else {
                commit('set_authenticated', true)

                commit('set_user_id', response.data.id)
                commit('set_id_no', response.data.id_no)
                commit('set_name', response.data.name)
                commit('set_position', response.data.positions)

                commit('set_branch_id', response.data.branch_id)
                commit('set_branch', response.data.branch.branch_code)
                commit('set_branch_address', response.data.branch.address)
                commit('set_branch_contact_no', response.data.branch.contact_no)

                commit('can_new_month', response.data.branch.can_new_month)
                commit('set_month_of', response.data.branch.transaction_month.month_of)
                commit('set_year', response.data.branch.transaction_month.year)
                commit('set_month_start', response.data.branch.transaction_month.month_start)
                commit('set_month_end', response.data.branch.transaction_month.month_end)

                commit('is_generating_savings_interest', response.data.branch.is_generating_savings_interest)
              }
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        }
      )
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios.post('api/logout-user-profile', data)
        .then(response => {
          commit('set_authenticated', false)

          commit('set_user_id', 0)
          commit('set_id_no', 0)
          commit('set_name', 'NA')
          commit('set_position', 'NA')

          // commit('set_branch_id', 0)
          commit('set_branch', 'NA')
          commit('set_branch_address', 'NA')
          commit('set_branch_contact_no', 'NA')

          commit('can_new_month', 0)
          commit('set_month_of', 'NA')
          commit('set_year', 'NA')
          commit('set_month_start', 'NA')
          commit('set_month_end', 'NA')

          commit('is_generating_savings_interest', 0)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
