import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from "axios";
import VueCroppie from 'vue-croppie';
import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import Cookie from "js-cookie";


Vue.use(VueCarousel);
Vue.use(VueCroppie);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

router.beforeResolve((to, from, next) => {
  const cookie = Cookie.get("XSRF-TOKEN");

  document.title = to.meta.title
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);
  const reqVisitor = to.matched.some(record => record.meta.requiresVisitor);

  const reqAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const requiresGM = to.matched.some(record => record.meta.requiresGM);
  const reqBranchManager = to.matched.some(record => record.meta.requiresBranchManager);
  const reqTeller = to.matched.some(record => record.meta.requiresTeller);
  const reqAccount = to.matched.some(record => record.meta.requiresAccount);
  const reqTellerCutOff = to.matched.some(record => record.meta.requiresTellerCutOff);
  const requiresGenerate = to.matched.some(record => record.meta.requiresGenerate);

  const loginQuery = {path: '/'};
  const dashboard = {path: '/dashboard'};
  const not_found = {path: '/error-404'};
  const generating = {path: '/generating-savings-interest'};

  if (reqAuth) {
    if (store.getters["authentication/authenticated"]) {
      if (store.getters["authentication/is_generating_savings_interest"] === 1) {
        if (requiresGenerate) {
          next();
        } else {
          next(generating);
        }
      } else {
        if (requiresGenerate) {
          next(dashboard);
        } else {
          var proceed = false;

          if (reqAdmin) {
            if (store.getters["authentication/position"] === 'ADMIN') {
              proceed = true;
            }
          }
          if (reqBranchManager) {
            if (store.getters["authentication/position"] === 'BRANCH MANAGER') {
              proceed = true;
            }
          }
          if (requiresGM) {
            if (store.getters["authentication/position"] === 'GENERAL MANAGER') {
              proceed = true;
            }
          }
          if (reqAccount) {
            if (store.getters["authentication/position"] === 'ACCOUNT') {
              proceed = true;
            }
          }
          if (reqTeller) {
            if (store.getters["authentication/position"] === 'TELLER') {
              proceed = true;
              if (reqTellerCutOff) {
                if (!store.getters["authentication/teller_already_cut_off"]) {
                  proceed = true;
                }
              } else {
                proceed = true;
              }
            }
          }

          //reqAuth Only
          if (
            !reqAdmin &&
            !reqBranchManager &&
            !requiresGM &&
            !reqAccount &&
            !reqTeller) {
            proceed = true
          }
          if (proceed) {
            next();
          } else {
            next(not_found);
          }

        }
      }
    } else {
      next(loginQuery);
    }
  } else if (reqVisitor) {
    if (store.getters["authentication/authenticated"]) {
      if (store.getters["authentication/authenticated"]) {
        next(dashboard);
      } else {
        next(loginQuery);
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    switch (error.response.status) {
      case 401: // Not logged in
      case 419: // Session expired
      case 503: // Down for maintenance
        // Bounce the user to the login screen with a redirect back
        if (store.getters["authentication/authenticated"]) {
          store.commit('authentication/set_authenticated', false)
          store.dispatch('authentication/logout_credentials', {id_no: store.getters["authentication/user_id"]}, {root: true});
        }
        router.push({path: '/'})
        break;
      case 500:
        return Promise.reject(error);
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
    }
  }
)
