require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        light_green: '#64dd17',
        light_orange: '#ffcc80',
        darken_orange: '#ff6d00',
        darken_green: '#33691e',
        black: '#000000',
        darken_brown: '#5d4037',
        darken_yellow: '#ba9e02',
      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        light_green: '#64dd17',
        light_orange: '#ffcc80',
        darken_orange: '#ff6d00',
        darken_green: '#33691e',
        black: '#000000',
        darken_brown: '#5d4037',
        darken_yellow: '#ba9e02',
      },
    },
  },
}
