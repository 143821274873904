<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      hidden-sm-and-down
    >
      <v-btn text>
        CutOff: {{month_end}}
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-select
        v-if="position==='ADMIN'||position==='GENERAL MANAGER'"
        class="mx-2"
        v-model="branch"
        :items="branch_items"
        item-text="branch_code"
        item-value="id"
        label="Branch"
        @change="change_db(branch_items.map(function (x) {
                    return x.id;
                }).indexOf(branch))"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  import moment from "moment";

  export default {
    name: "AppBarHeader",
    data() {
      return {
        today_date: '',
        today_time: '',
        branch: '',
        can_new_month: false,

        branch_items: [],
      }
    },
    created() {
      if (this.position === 'ADMIN' || this.position === 'GENERAL MANAGER') {
        const data = new FormData()
        data.append('active', 0);
        this.get_list_of_all_active_branch(data)
          .then(response => {
            this.branch_items = response.data
            this.$nextTick(() => {
              this.branch = 1
              this.change_branch_id(this.branch_items[0])
            });
          })
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'month_end']),
    },
    methods: {
      ...mapActions('branch', ['get_list_of_all_active_branch']),
      ...mapActions('authentication', ['change_branch_id']),
      change_db(index) {
        this.change_branch_id(this.branch_items[index])
      },
    },
  }
</script>

<style scoped>

</style>
