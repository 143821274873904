const getDefaultState = () => {
    return {
        rules: {
            non_empty_field_rule: [
                v => !!v || 'This field is required'
            ],
            combobox_rule: [
                v => !!v || 'Item is required'
            ],
            default_no_empty_rule: [
                v => !!v || 'This field is required'
            ],
            default_max_45_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 45 || 'Must not exceed 45 characters'
            ],
            default_max_45_character_rule: [
                v => (v || '').length <= 45 || 'Must not exceed 45 characters'
            ],
            default_max_25_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 25 || 'Must not exceed 25 characters'
            ],
            default_max_25_character_rule: [
                v => (v || '').length <= 25 || 'Must not exceed 25 characters'
            ],
            default_max_75_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 75 || 'Must not exceed 75 characters'
            ],
            default_max_150_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 150 || 'Must not exceed 150 characters'
            ],
            default_max_100_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 100 || 'Must not exceed 100 characters'
            ],
            default_max_15_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 15 || 'Must not exceed 15 characters'
            ],
            default_max_20_character_rule: [
                v => (v || '').length <= 20 || 'Must not exceed 20 characters'
            ],
            default_max_255_character_and_no_empty_rule: [
                v => !!v || 'This field is required',
                v => (v || '').length <= 255 || 'Must not exceed 255 characters'
            ],
            default_max_255_character_rule: [
                v => (v || '').length <= 255 || 'Must not exceed 255 characters'
            ],
            input_file_rule: [
                v => !v || v.size > 0 || 'File should not be empty!',
            ],
        }
    }
}

const state = getDefaultState()


const getters = {
    rules: state => state.rules,
}


export default {
    namespaced: true,
    state,
    getters,
}