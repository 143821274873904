<template>
  <v-layout column wrap>
    <v-dialog v-model="dialog_show" persistent scrollable max-width="400">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOAD TRANSMITAL OR</h4></v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid_form" lazy-validation>
              <v-flex xs12 v-if="loading === false">
                <v-layout column wrap>
                  <v-flex xs12 md3>
                    <v-file-input
                      show-size
                      v-model="files"
                      label="Select DB File"
                      :rules="rules.input_file_rule"
                    ></v-file-input>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex v-else>
                <v-layout column wrap>
                  <v-flex xs12 class="text-center" style="margin-left: 100px; margin-right: 120px">
                    <v-progress-circular
                      :size=120
                      :width="7"
                      color="primary"
                      indeterminate
                    >
                      Please wait a moment...
                    </v-progress-circular>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn v-if="loading === false" class="mx-1" color="primary" @click="restore_db()">
            Restore
          </v-btn>
          <v-btn v-if="loading === false" class="mx-1" outlined color="primary"
                 @click="close_dialog()">Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--    dialog here-->
    <v-dialog v-model="show" persistent width="50%" dark>
      <v-card color="primary">
        <v-card-text>
          {{ txt }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import errorDialog from '@/components/dialogs/notifications_dialog/ErrorDialog'
  import moment from "moment";

  export default {
    components: {
      errorDialog,
    },
    data() {
      return {
        show: false,
        txt: '',

        alert: false,
        alert_message: '',
        //error dialog
        error_dialog: false,
        error_message: '',
        error_main_message: '',
        error_toolbar_color: '',
        error_toolbar_title: '',
        //others
        files: [],
        loading: false,
        valid_form: true,
      }
    },
    watch: {
      dialog_show: function () {
        if (!this.dialog_show) {
          this.error_dialog = false
          this.error_message = ''
          this.error_main_message = ''
          this.error_toolbar_color = ''
          this.error_toolbar_title = ''
          this.files = []
          this.loading = false
          this.valid_form = false
        }
      }
    },
    beforeDestroy() {
      this.error_dialog = false
      this.error_message = ''
      this.error_main_message = ''
      this.error_toolbar_color = ''
      this.error_toolbar_title = ''
      this.files = ''
      this.loading = false
      this.valid_form = false
    },
    props: {
      dialog_show: Boolean,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('db_file', ['receive_transmital_or']),
      close_dialog() {
        this.$emit('dialog_value', false)
        this.$refs.form.resetValidation()
      },
      restore_db() {
        if (this.$refs.form.validate()) {
          this.loading = true
          if (this.files.name.substr(this.files.name.length - 4, this.files.name.length) === 'pptx') {
            const data = new FormData()
            data.append('file', this.files)
            this.receive_transmital_or(data)
              .then((response) => {
                if (response.data != 1) {
                  this.loading = false
                } else {
                  location.reload()
                  this.loading = false
                }
              })
              .catch(error => {
                console.log(error)
              })
          } else {
            this.alert_message = 'File is not valid!'
            this.loading = false
          }
        }
      },
    }
  }
</script>
