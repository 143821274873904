<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="w-full">
        <div class="d-flex align-center">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <app-bar-header></app-bar-header>
          <v-spacer></v-spacer>
          <theme-switcher></theme-switcher>
          <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <v-btn
            icon

            class="ms-3"
            v-if="position==='BRANCH MANAGER' && can_new_month"
            @click="start_new_month_transaction"
          >
            <v-icon>
              {{ icons.mdiReload }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main :key="branch_id">
      <div class="app-content-container" :key="$router.currentRoute.name">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2022 <a
            href=""
            class="text-decoration-none"
          >GOODLIFE ITD</a></span>
          <span class="d-sm-inline d-none">
            Month of Operation: <a
            href=""
            class="text-decoration-none"
          >{{month_of}}</a>
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
  import {ref} from '@vue/composition-api'
  import {mdiMagnify, mdiBellOutline, mdiGithub, mdiReload} from '@mdi/js'
  import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
  import ThemeSwitcher from './components/ThemeSwitcher.vue'
  import AppBarUserMenu from './components/AppBarUserMenu.vue'
  import AppBarHeader from "@/layouts/components/vertical-nav-menu/AppBarHeader";
  import {mapGetters} from "vuex";

  export default {
    components: {
      AppBarHeader,
      VerticalNavMenu,
      ThemeSwitcher,
      AppBarUserMenu,
    },
    setup() {
      const isDrawerOpen = ref(null)

      return {
        isDrawerOpen,

        // Icons
        icons: {
          mdiMagnify,
          mdiBellOutline,
          mdiReload,
        },
      }
    },
    data() {
      return {
        show: false,
        txt: '',
        can_new_month: false,
      }
    },
    computed: {
      ...mapGetters('authentication', ['position', 'month_of', 'branch_id']),
    },
  }
</script>

<style lang="scss" scoped>
  .v-app-bar ::v-deep {
    .v-toolbar__content {
      padding: 0;

      .app-bar-search {
        .v-input__slot {
          padding-left: 18px;
        }
      }
    }
  }

  .boxed-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
