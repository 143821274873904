<template>
  <v-layout column wrap>
    <v-dialog v-model="error_dialog_value" persistent max-width="500">
      <v-toolbar dense dark color="error">
        <v-toolbar-title>
          <v-icon>warning</v-icon>
          Message
        </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <h3 v-show="error_message===''" class="font-weight-regular">Error Occured! Please
              contact the IT Department!</h3>
            <h3 class="font-weight-regular">{{ error_message }}</h3>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-1" color="error" @click="close_dialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

  export default {
    props: {
      error_dialog_value: Boolean,
      error_message: String
    },
    methods: {
      close_dialog() {
        this.$emit('error_dialog_value', false)
      },
    }
  }
</script>
