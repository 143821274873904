import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/new-membership',
    name: 'new-membership',
    component: () => import('../views/register_interface/NewMembership'),
    meta: {
      title: 'New Membership',
      requiresAuth: true,
      requiresAccount: true,
    },
  },
  {
    path: '/new-corporate-account',
    name: 'new-corporate-account',
    component: () => import('../views/register_interface/NewCorporateAccount'),
    meta: {
      title: 'New Corporate Account',
      requiresAuth: true,
      requiresAccount: true,
    },
  },
  {
    path: '/new-bank-depositories',
    name: 'new-bank-depositories',
    component: () => import('../views/register_interface/BankDepositories'),
    meta: {
      title: 'New Bank Depositories',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-branch-details',
    name: 'new-branch-details',
    component: () => import('../views/register_interface/BranchInformation'),
    meta: {
      title: 'New Branch Details',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-or-series',
    name: 'load-or-series',
    component: () => import('../views/load_interface/OrSeries'),
    meta: {
      title: 'Load Or Series',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-staff-info',
    name: 'new-staff-info',
    component: () => import('../views/register_interface/Staff'),
    meta: {
      title: 'New Staff Info',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-deposit-slip',
    name: 'new-deposit-slip',
    component: () => import('../views/load_interface/DepositSlips'),
    meta: {
      title: 'New Deposit Slip',
      requiresAuth: true,
      requiresBranchManager: true,
      requiresAccount: true,
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/payments/Payments'),
    meta: {
      title: 'Payments',
      requiresAuth: true,
      requiresBranchManager: true,
      requiresAccount: true,
    },
  },
  {
    path: '/approve-loan-application',
    name: 'approve-loan-application',
    component: () => import('../views/create_interface/LoansApplication'),
    meta: {
      title: 'To Approve Loans',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/loan-application',
    name: 'loan-application',
    component: () => import('../views/create_interface/LoansApplicationRequest'),
    props: {value: {}},
    meta: {
      title: 'Loans Application',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/gadget-loan-application',
    name: 'gadget-loan-application',
    component: () => import('../views/create_interface/GadgetLoansApplication'),
    meta: {
      title: 'Gadget Loans Application',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/open-account-savings',
    name: 'open-account-savings',
    component: () => import('../views/create_interface/OpenAccountRegularSavings'),
    meta: {
      title: 'Open Account Savings',
      requiresAuth: true,
      requiresAccount: true,
    },
  },
  {
    path: '/open-coop-protek-savings',
    name: 'open-coop-protek-savings',
    component: () => import('../views/create_interface/OpenCoopProtekSavings'),
    meta: {
      title: 'Open Coop Savings Protek',
      requiresAuth: true,
      requiresAccount: true,
    },
  },
  {
    path: '/time-deposit-application',
    name: 'time-deposit-application',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsTime'),
    meta: {
      title: 'Time Deposit Application',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/time-deposit-application-less',
    name: 'time-deposit-application-less',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsTimeLess'),
    meta: {
      title: 'Time Deposit Application Less',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/share-capital-application',
    name: 'share-capital-application',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsShareCapital'),
    meta: {
      title: 'Share Capital Application',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/share-capital-for-approval',
    name: 'share-capital-for-approval',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsShareCapitalForApproval'),
    meta: {
      title: 'Share Capital For Approval',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import('../views/register_interface/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-deposit-slip-regular-savings',
    name: 'new-deposit-slip-regular-savings',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsRegularSavings'),
    meta: {
      title: 'New Savings Deposit Slip',
      requiresAuth: true,
      requiresTeller: true,
      requiresTellerCutOff: true,
    },
  },
  {
    path: '/new-withdrawal-slip-regular-savings',
    name: 'new-withdrawal-slip-regular-savings',
    component: () => import('../views/load_interface/withdrawals_interface/WithdrawalSlipsRegularSavings'),
    meta: {
      title: 'New Savings Withdrawal Slip',
      requiresAuth: true,
      requiresTeller: true,
      requiresTellerCutOff: true,
    },
  },
  {
    path: '/approval-share-capital-withdrew',
    name: 'approval-share-capital-withdrew',
    component: () => import('../views/admin_interface/ListOfShareCapitalForApproveWithdrew'),
    meta: {
      title: 'Withdrew Share Capital For Approve',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTeller: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/approval-time-dep-withdrew',
    name: 'approval-time-dep-withdrew',
    component: () => import('../views/admin_interface/ListOfTimeDepositForApproveWithdrew'),
    meta: {
      title: 'Withdrew Time Dep For Approve',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTeller: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/approval-loan-savings-withdrew',
    name: 'approval-loan-savings-withdrew',
    component: () => import('../views/admin_interface/ListOfLoanSavingsForApproveWithdrew'),
    meta: {
      title: 'Withdrew Loan Savings For Approve',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTeller: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/approval-share-capital-deposit',
    name: 'approval-share-capital-deposit',
    component: () => import('../views/admin_interface/ListOfShareCapitalForApproveDeposit'),
    meta: {
      title: 'Deposit Share Capital For Approve',
      requiresAuth: true,
      requiresAdmin: true,
      requiresTeller: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/regular-savings-passbook-printing',
    name: 'regular-savings-passbook-printing',
    component: () => import('../views/load_interface/PrintingPassbookRegularSavings'),
    meta: {
      title: 'Savings Passbook Printing',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/monthly-collection-reports',
    name: 'monthly-collection-reports',
    component: () => import('../views/reports_interface/MonthlyCollectionReports'),
    meta: {
      title: 'Monthly Collection Reports',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/monthly-interest-collection-reports',
    name: 'monthly-interest-collection-reports',
    component: () => import('../views/reports_interface/MonthlyInterestCollectionReport'),
    meta: {
      title: 'Monthly Interest Collection Reports',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/monthly-principal-collection-reports',
    name: 'monthly-principal-collection-reports',
    component: () => import('../views/reports_interface/MonthlyInterestCollectionReport'),
    meta: {
      title: 'Monthly Principal Collection Reports',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/monthly-quota-reports',
    name: 'monthly-quota-reports',
    component: () => import('../views/reports_interface/MonthlyQuotaReports'),
    meta: {
      title: 'Monthly Quota Reports',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/teller-fund',
    name: 'teller-fund',
    component: () => import('../views/admin_interface/TellerFund'),
    meta: {
      title: 'Teller Fund',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/search-regular-savings-member',
    name: 'search-regular-savings-member',
    component: () => import('../views/search_interface/SearchMemberRegularSavings'),
    meta: {
      title: 'Search Regular Savings Accounts',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/search-members',
    name: 'search-members',
    component: () => import('../views/search_interface/SearchMembers'),
    meta: {
      title: 'Search Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/search-coop-protek-members',
    name: 'search-coop-protek-members',
    component: () => import('../views/search_interface/SearchCoopProtek'),
    meta: {
      title: 'Search Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-bank-depositories',
    name: 'list-of-bank-depositories',
    component: () => import('../views/view_interface/ListOfBankDepository'),
    meta: {
      title: 'List Of Bank Depository',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/remittances',
    name: 'remittances',
    component: () => import('../views/view_interface/Remittances'),
    meta: {
      title: 'Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/unpaid-loans',
    name: 'unpaid-loans',
    component: () => import('../views/reports_interface/UnpaidLoans'),
    meta: {
      title: 'Unpaid Loans',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-loans',
    name: 'list-of-loans',
    component: () => import('../views/reports_interface/ListOfLoan'),
    meta: {
      title: 'List of Loans',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-have-mortuary',
    name: 'list-of-have-mortuary',
    component: () => import('../views/reports_interface/ListOfMortuary'),
    meta: {
      title: 'List of Have Mortuary Insurance',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-new-members',
    name: 'list-of-new-members',
    component: () => import('../views/reports_interface/ListOfNewMember'),
    meta: {
      title: 'List of New Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-new-corporate-members',
    name: 'list-of-new-corporate-members',
    component: () => import('../views/reports_interface/ListOfNewCorporateMembership'),
    meta: {
      title: 'List Of New Corporate Membership',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/sales-on-receipt',
    name: 'sales-on-receipt',
    component: () => import('../views/admin_interface/cais_reports/CaisGrossSalesPerReceipt'),
    meta: {
      title: 'CAIS Gross Sales/Receipt',
      requiresAuth: true,
      requiresAdmin: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-time-deposits',
    name: 'list-of-time-deposits',
    component: () => import('../views/reports_interface/ListOfTimeDeposit'),
    meta: {
      title: 'List of Time Deposits',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-open-accounts',
    name: 'list-of-open-accounts',
    component: () => import('../views/reports_interface/ListOfOpenAccount'),
    meta: {
      title: 'List of Open Account',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-coop-protek-accounts',
    name: 'list-of-coop-protek-accounts',
    component: () => import('../views/reports_interface/ListOfCoopProtekAccount'),
    meta: {
      title: 'List of Coop Protek Account',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresAccount: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-processing-fee',
    name: 'list-of-processing-fee',
    component: () => import('../views/reports_interface/ListOfProcessingFee'),
    meta: {
      title: 'List of Processing Fee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-nmapi-fee',
    name: 'list-of-nmapi-fee',
    component: () => import('../views/reports_interface/ListOfNMAPIFee'),
    meta: {
      title: 'List of NMAPI Fee',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-share-capital',
    name: 'list-of-share-capital',
    component: () => import('../views/reports_interface/ListOfShareCapital'),
    meta: {
      title: 'List of Share Capital',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-foundation',
    name: 'list-of-foundation',
    component: () => import('../views/reports_interface/ListOfFoundation'),
    meta: {
      title: 'List of Foundation',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/paid-loans',
    name: 'paid-loans',
    component: () => import('../views/reports_interface/PaidLoans'),
    meta: {
      title: 'Paid Loans',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/teller-transaction',
    name: 'teller-transaction',
    component: () => import('../views/reports_interface/TellerTransaction'),
    meta: {
      title: 'Teller Transaction',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresTeller: true,
      requiresGM: true,
    },
  },
  {
    path: '/income-summary',
    name: 'income-summary',
    component: () => import('../views/admin_interface/income_statement/IncomeSummary'),
    meta: {
      title: 'Income Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/expenses-summary',
    name: 'expenses-summary',
    component: () => import('../views/admin_interface/expense_statement/ExpensesSummary'),
    meta: {
      title: 'Expenses Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/payables-summary',
    name: 'payables-summary',
    component: () => import('../views/admin_interface/payables_statement/PayablesSummary'),
    meta: {
      title: 'Payables Summary',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/summary-report',
    name: 'summary-report',
    component: () => import('../views/reports_interface/SummaryReport'),
    meta: {
      title: 'Summary Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/raw-lots-data',
    name: 'raw-lots-data',
    component: () => import('../views/view_interface/raw_lots_data/RawLotsData'),
    meta: {
      title: 'Raw Lots Data',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-raw-lots-payment',
    name: 'list-of-raw-lots-payment',
    component: () => import('../views/reports_interface/ListOfRawLotsPayment'),
    meta: {
      title: 'List of Raw Lots Payment',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/summary-collection-report',
    name: 'summary-collection-report',
    component: () => import('../views/reports_interface/SummaryCollection'),
    meta: {
      title: 'Summary Collection Report',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/withdrawals-selection',
    name: 'withdrawals-selection',
    component: () => import('../views/load_interface/WithdrawalSelection'),
    meta: {
      title: 'Withdrawals Selection',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/loan-interest-income-summary',
    name: 'loan-interest-income-summary',
    component: () => import('../views/admin_interface/income_statement/LoanInterestIncomeBreakDown'),
    meta: {
      title: 'Loan Interest Income BreakDown',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/search-or-no-remittances',
    name: 'search-or-no-remittances',
    component: () => import('../views/search_interface/SearchRemittances'),
    meta: {
      title: 'Search OR # Remittances',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/monitoring-official-receipt',
    name: 'monitoring-official-receipt',
    component: () => import('../views/view_interface/monitoring/OfficialReceipt'),
    meta: {
      title: 'Monitoring Official Receipt',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-members',
    name: 'list-of-members',
    component: () => import('../views/admin_interface/ListOfMembers'),
    meta: {
      title: 'List of Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresGM: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('../views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/pages/register_interface',
    name: 'pages-register_interface',
    component: () => import('../views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      title: 'Register',
      requiresAuth: true,
    },
  },
  {
    path: '/generating-savings-interest',
    name: 'generating-savings-interest',
    component: () => import('../views/GeneratingSavingsInterest'),
    meta: {
      requiresAuth: true,
      requiresGenerate: true,
      layout: 'blank',
      title: 'Generating Savings Interest',
    },
  },
  {
    path: '/cais-membership-count',
    name: 'cais-membership-count',
    component: () => import('../views/admin_interface/cais_reports/CaisMembershipCount'),
    meta: {
      title: 'CAIS Membership Count',
      requiresAuth: true,
      requiresAdmin: true,
      requiresGM: true,
    },
  },
  {
    path: '/cais-age-group-members',
    name: 'cais-age-group-members',
    component: () => import('../views/admin_interface/cais_reports/CaisAgeGroupOfMembers'),
    meta: {
      title: 'CAIS Age Group of Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresGM: true,
    },
  },
  {
    path: '/or-reassigned',
    name: 'or-reassigned',
    component: () => import('../views/load_interface/ORReAssign'),
    meta: {
      title: 'OR Re-assigned',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-loan-insurance',
    name: 'list-of-loan-insurance',
    component: () => import('../views/reports_interface/ListOfLoanInsurance'),
    meta: {
      title: 'List of Loan Insurance',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-gadget-loans',
    name: 'list-of-gadget-loans',
    component: () => import('../views/reports_interface/ListOfGadgetLoan'),
    meta: {
      title: 'List of Gadget Loan',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/loan-savings-payables-summary',
    name: 'loan-savings-payables-summary',
    component: () => import('../views/admin_interface/payables_statement/LoanSavingsPayableBreakDown'),
    meta: {
      title: 'Loan Savings Payables BreakDown',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/time-deposit-payables-summary',
    name: 'time-deposit-payables-summary',
    component: () => import('../views/admin_interface/payables_statement/TimeDepositPayableBreakDown'),
    meta: {
      title: 'Time Deposit Payables BreakDown',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/yearly-dividend',
    name: 'yearly-dividend',
    component: () => import('../views/admin_interface/YearlyDividend'),
    meta: {
      title: 'Yearly Dividend',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/financial-statement',
    name: 'financial-statement',
    component: () => import('../views/admin_interface/FinancialStatement'),
    meta: {
      title: 'Financial Statement',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/time-deposit-not-yet-released',
    name: 'time-deposit-not-yet-released',
    component: () => import('../views/reports_interface/ListOfTimeDepositNotYetReleased'),
    meta: {
      title: 'List Of Time Deposit Not Yet Released',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-delinquent-account',
    name: 'list-of-delinquent-account',
    component: () => import('../views/admin_interface/ListOfDelinquentAccount'),
    meta: {
      title: 'List Of Delinquent Account',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/register-expenses',
    name: 'register-expenses',
    component: () => import('../views/admin_interface/expense_statement/RegisterExpenses'),
    meta: {
      title: 'Register Expenses',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-loan-not-yet-approved',
    name: 'list-of-loan-not-yet-approved',
    component: () => import('../views/admin_interface/ListOfLoansNotYetApprove'),
    meta: {
      title: 'List of Loan Not Yet Approve',
      requiresAuth: true,
      requiresBranchManager: true,
      requiresAdmin: true,
      requiresGM: true,
    },
  },
  {
    path: '/regular-savings-payables-summary',
    name: 'regular-savings-payables-summary',
    component: () => import('../views/admin_interface/payables_statement/RegularSavingsBreakDown'),
    meta: {
      title: 'Regular Savings BreakDown',
      requiresAuth: true,
      requiresBranchManager: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-passbooks',
    name: 'load-passbooks',
    component: () => import('../views/load_interface/Passbook'),
    meta: {
      title: 'Load Passbooks',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/transmital-passbooks',
    name: 'transmital-passbooks',
    component: () => import('../views/load_interface/TransmitalPassbook'),
    meta: {
      title: 'Transmital Passbook',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/passbooks-monitoring',
    name: 'passbooks-monitoring',
    component: () => import('../views/load_interface/PassbookMonitoring'),
    meta: {
      title: 'Passbook Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresTeller: true,
    },
  },
  {
    path: '/undeposited-passbooks-monitoring',
    name: 'undeposited-passbooks-monitoring',
    component: () => import('../views/load_interface/UnDepositedPassbook'),
    meta: {
      title: 'UnDeposited Passbook Monitoring',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresTeller: true,
    },
  },
  {
    path: '/list-of-reg-save-from-loan',
    name: 'list-of-reg-save-from-loan',
    component: () => import('../views/reports_interface/ListOfRegularSavingsFromLoan'),
    meta: {
      title: 'Reg Save From Loan',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/yearly-dividend-for-released',
    name: 'yearly-dividend-for-released',
    component: () => import('../views/admin_interface/ForReleasedYearlyDividend'),
    meta: {
      title: 'For Released Yearly Dividend',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/share-capital-from-loan',
    name: 'share-capital-from-loan',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsShareCapitalFromLoan'),
    meta: {
      title: 'From Loan Share Capital',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/regular-savings-from-loan',
    name: 'regular-savings-from-loan',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsRegularSavingsFromLoan'),
    meta: {
      title: 'From Loan Regular Savings',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/share-capital-from-approved',
    name: 'share-capital-from-approved',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsShareCapitalApproved'),
    meta: {
      title: 'From Approved Share Capital',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/share-capital-approved',
    name: 'share-capital-approved',
    component: () => import('../views/load_interface/deposits_interface/DepositSlipsShareCapitalFromLoan'),
    meta: {
      title: 'From Loan Share Capital',
      requiresAuth: true,
      requiresTeller: true,
    },
  },
  {
    path: '/branch-manager-incentives',
    name: 'branch-manager-incentives',
    component: () => import('../views/reports_interface/BranchManagerIncentives'),
    meta: {
      title: 'Branch Manager Incentives',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/yearly-branch-manager-incentives',
    name: 'yearly-branch-manager-incentives',
    component: () => import('../views/reports_interface/YearlyBranchManagerIncentives'),
    meta: {
      title: 'Yearly Branch Manager Incentives',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/loan-calculator',
    name: 'loan-calculator',
    component: () => import('../views/admin_interface/calculator/LoansCalculator'),
    meta: {
      title: 'Loans Calculator',
      requiresAuth: true,
    },
  },
  {
    path: '/time-deposit-calculator',
    name: 'time-deposit-calculator',
    component: () => import('../views/load_interface/deposits_interface/TimeDepositData'),
    meta: {
      title: 'Time Deposit Calculator',
      requiresAuth: true,
    },
  },
  {
    path: '/new-organization-group',
    name: 'new-organization-group',
    component: () => import('../views/register_interface/OrganizationGroup'),
    meta: {
      title: 'New Organization Group',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-loan-transmital',
    name: 'new-loan-transmital',
    component: () => import('../views/create_interface/LoanTransmital'),
    meta: {
      title: 'New Loan Transmittal',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/pending-loans-transmital',
    name: 'pending-loans-transmital',
    component: () => import('../views/admin_interface/ListOfPendingTransmital'),
    meta: {
      title: 'Pending Loans Transmital',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/terminated-members',
    name: 'terminated-members',
    component: () => import('../views/reports_interface/ListOfTerminatedMember'),
    meta: {
      title: 'List Of Terminated Member',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/transfer-accounts-to-collectors',
    name: 'transfer-accounts-to-collectors',
    component: () => import('../views/load_interface/TransferLoansToCollector'),
    meta: {
      title: 'Transfer Loans To Collector',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-loans-savings',
    name: 'list-of-loans-savings',
    component: () => import('../views/reports_interface/ListOfLoanSavings'),
    meta: {
      title: 'List Of Loan Savings',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/list-of-loans-savings-withdrew',
    name: 'list-of-loans-savings-withdrew',
    component: () => import('../views/reports_interface/ListOfLoanSavingsWithdrew'),
    meta: {
      title: 'List Of Loan Savings Withdrew',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
      requiresGM: true,
    },
  },
  {
    path: '/organizational-chart',
    name: 'organizational-chart',
    component: () => import('../views/admin_interface/OrganizationalChart'),
    meta: {
      title: 'Organizational Chart',
      requiresAuth: true,
    },
  },
  {
    path: '/new-officer-or-committee',
    name: 'new-officer-or-committee',
    component: () => import('../views/register_interface/NewCommitteeOrOfficer'),
    meta: {
      title: 'New Committee Or Officer',
      requiresAuth: true,
    },
  },
  {
    path: '/bank-tally',
    name: 'bank-tally',
    component: () => import('../views/admin_interface/BankTally'),
    meta: {
      title: 'Bank Tally',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/bank-statement-tally',
    name: 'bank-statement-tally',
    component: () => import('../views/admin_interface/BankStatementTally'),
    meta: {
      title: 'Bank Statement Tally',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-inventories',
    name: 'load-inventories',
    component: () => import('../views/admin_interface/inventory/LoadInventory'),
    meta: {
      title: 'Load Inventory',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/inventory-report',
    name: 'inventory-report',
    component: () => import('../views/admin_interface/inventory/InventoryReport'),
    meta: {
      title: 'Inventory Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('../views/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  routes
})

export default router
